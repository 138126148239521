import React, { useEffect, useState, useContext } from "react";
import Check from "assets/icons/check.svg";
import { Link, navigate } from "gatsby";
import DataProvider from "Context/DataContext";

export default function SuccessQuote() {
  const { paymentInfo, accountInfo } = useContext(DataProvider);
  const userEmail = accountInfo?.user?.email;
  const userName = accountInfo?.user?.username;
  const data = paymentInfo?.data;

  useEffect(() => {
    if (!data && !localStorage.getItem("order_id")) {
      navigate("/quote");
    }

    typeof window !== "undefined" &&
      typeof window.gtag !== "undefined" &&
      window.gtag("event", "conversion", {
        send_to: "AW-1001672297/3SYpCPrut6MDEOmc0d0D",
        transaction_id: localStorage.getItem("order_id"),
      });
  }, []);

  const style = {
    background:
      "transparent linear-gradient(179deg, #F1F6F8 0%, #F9FAFB 61%, var(--unnamed-color-ffffff) 100%) 0% 0% no-repeat padding-box;",
  };
  var targetLanguagePairString = "";

  data?.quote?.quote_items?.map((quote_item, index) => {
    if (index !== data.quote.quote_items.length - 1) {
      targetLanguagePairString += quote_item.target_language + ",";
    } else {
      targetLanguagePairString += quote_item.target_language;
    }
  });

  const guestUserEmail = data?.quote?.customer_email;
  // console.log(userName);
  // console.log(userEmail);

  return (
    <div className="flex items-center justify-center w-full" style={style}>
      <div className="flex items-center justify-center  w-11/12 md:w-10/12 2xl:w-8/12 lg:my-32 my-16 flex-col lg:mx-auto mr-auto ">
        <div className="box border border-green-600 flex-col rounded-xl flex items-center justify-center sm:justify-start bg-[#E6ECE6] w-10/12 p-5 md:p-7 max-w-5xl">
          <div className="w-full flex flex-col  sm:flex-row justify-center sm:justify-start items-center gap-5 sm:gap-0">
            <img
              src={Check}
              alt="loading..."
              className="w-10 h-10 sm:mr-5 self-center"
            />
            <p className="font-secondary font-semibold text-[21px] sm:text-24 md:text-4xl  text-black mb-3">
              Payment Successful!
            </p>
          </div>

          <p className="text-base font-opensans leading-[32px] lg:ml-[59px]">
            Thank you for your order. Your order confirmation and delivery
            details have been successfully sent to{" "}
            {userEmail ? userEmail : guestUserEmail}. If you have any questions
            or concerns, feel free to contact us.
          </p>
        </div>
        <div className="w-full flex items-center flex-col md:p-12 py-5 border-b border-blackNew-400 mb-6 max-w-5xl">
          <p className="font-secondary text-xl font-medium w-full text-left ml-8">
            Order Summary
          </p>
          <div className="flex flex-col lg:flex-row items-center lg:justify-between  w-full lg:px-12 px-4 xl:flex-nowrap flex-wrap mt-6">
            <div className="flex items-center justify-center flex-col lg:w-5/12 w-full gap-1">
              <p className="flex font-sm w-full gap-2">
                <span className="font-semibold ">Payment Date : </span>{" "}
                <span className="break-all">
                  {data?.payment_info?.invoice_date}
                </span>
              </p>
              <p className="flex font-sm w-full gap-2">
                <span className="font-semibold mr-3">Reference Number:</span>{" "}
                <span className="break-all">
                  {data?.payment_info?.invoice_number}
                </span>
              </p>
              <p className="flex font-sm w-full gap-2">
                <span className="font-semibold ">Payment Summary: </span>{" "}
                <span className="break-all">
                  ${data?.payment_info?.payment_amount}
                </span>
              </p>
              <p className="flex font-sm w-full gap-2">
                <span className="font-semibold ">Name: </span>{" "}
                <span className="break-words">{userName ? userName : ""}</span>
              </p>
            </div>
            <div className="flex items-center justify-center flex-col lg:mt-0 sm:mt-10 mt-6 lg:w-5/12 w-full gap-1">
              <p className="font-semibold text-sm font-opensans flex justify-between w-full">
                <span>Order details:</span>
                <span></span>
              </p>
              <p className="font-opensans text-sm leading-[30px] flex gap-2 w-full">
                <span>Project Name : </span>{" "}
                <span className="break-words">
                  {" "}
                  {data?.quote?.project_name}
                </span>
              </p>
              <p className="font-opensans text-sm leading-[30px] flex gap-2 w-full">
                <span>Language Pair:</span>
                <span className="break-words">
                  {data?.quote?.source_language} - {targetLanguagePairString}
                </span>
              </p>
              <p className="font-opensans text-sm leading-[30px] flex gap-2 w-full">
                <span>Number of Words:</span>
                <span className="break-all">{data?.quote?.total_words}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-7">
          <Link
            to="/quote"
            className="text-base font-opensans leading-[40px] underline text-center"
          >
            Create New Request
          </Link>
        </div>
      </div>
    </div>
  );
}
