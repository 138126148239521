import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

function QuoteHeader() {
  return (
    <>
      <div className="hidden sm:flex items-center justify-center bg-black ">
        <div className="max-w-screen-xl flex items-center justify-between w-full py-5 px-3">
          <div>
            <Link to="/">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/Tomedes_Logo.svg"
                alt="logo"
                className="md:w-48 w-[110px] h-[22px] md:h-10"
              />
            </Link>
          </div>
          <div>
            <div className="flex flex-col  relative mr-32 2xl:mr-0">
              <p className="text-white md:text-base text-sm font-light font-secondary w-full md:text-left text-right">
                Need help? &nbsp;
                <Link to="/contactus.php" className="underline">
                  Contact us
                </Link>
                .
              </p>

              <h2 className="text-xs font-secondary font-normal text-quotetext-500 w-full text-right">
                <a href="tel:+19852390142">US: +1 985 239 0142</a> |{" "}
                <span className="md:inline-block hidden">
                  <a href="tel:+441615096140">UK: +44 (0)16 1509 6140</a>
                </span>
              </h2>

              <p className="md:hidden block text-xs font-secondary font-normal text-quotetext-500 w-full text-right">
                UK: +44 1615 096140
              </p>
              <p className="font-secondary text-start text-[#667495] text-sm">
                <a href="mailto:support@tomedes.com">support@tomedes.com</a>
              </p>
              <a
                href="https://api.whatsapp.com/send?phone=972722200700"
                target="_blank"
                className="absolute -right-16 top-0 cursor-pointer"
              >
                <OptimizedImage
                  src="https://tomedes.gumlet.io/frontend/images/location_new/whatsapp_bubble.svg"
                  alt="whatsApp"
                  className="w-10 h-10"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-black sm:hidden w-full p-6 relative">
        <Link to="/">
          <OptimizedImage
            src="https://tomedes.gumlet.io/assets/images/Tomedes_Logo.svg"
            alt="logo"
            className="md:w-48 w-[110px] h-[22px] md:h-10"
          />
        </Link>
        <br />
        <p className="text-xs font-secondary font-normal text-quotetext-500 w-full">
          US: +1 985 239 0142 | UK: +44 1615 096140
        </p>
        <p className="font-secondary text-start text-[#667495] text-sm">
          <a href="mailto:support@tomedes.com">support@tomedes.com</a>
        </p>
        <a
          href="https://api.whatsapp.com/send?phone=972722200700"
          target="_blank"
          className="absolute right-6 top-9 cursor-pointer"
        >
          <OptimizedImage
            src="https://tomedes.gumlet.io/frontend/images/location_new/whatsapp_bubble.svg"
            alt="whatsApp"
            className="w-10 h-10"
          />
        </a>
      </div>
    </>
  );
}

export default QuoteHeader;
