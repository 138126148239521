import React from 'react';
import SucessQuote from "components/Quote/OnSuccessComp/SuccessQuote";
import QuoteHeader from "components/Quote/Quotelayout/QuoteHeader";


function index() {
    return (
        <div className="overflow-x-hidden">
        <QuoteHeader/>
           <SucessQuote/> 
        </div>
    )
}

export default index